<template>
  <f7-page
    name="articlePage"
    class="article-page no-navbar"
    :style="{'--f7-page-navbar-offset': 0}"
  >
    <f7-navbar
      back-link
      :title="article.title"
      transparent
    />
    <ArticleCard
      type="header"
      :article="article"
      :animate="animate"
    />
    <div class="article-page__content">
      <p class="article-page__perex">{{article.perex}}</p>
      <div v-html="content"></div>
    </div>
  </f7-page>
</template>
<script>
import {has} from 'lodash';
import ArticleCard from '../components/Article/ArticleCard.vue';

export default {
  components: {
    ArticleCard,
  },
  props: {
    article: {
      type: Object,
      required: true,
    }
  },
  computed: {
    content() {
      const parser = new DOMParser();
      const content = parser.parseFromString(this.article.content, 'text/html');
      for (const el of content.querySelectorAll('a')) {
        /** @type {HTMLAnchorElement} */
        const a = el;
        a.classList.add('external');
        a.target = '_blank';
      }
      for (const el of content.querySelectorAll('table')) {
        el.classList.add('article-page__table');
        el.outerHTML = `<div class="article-page__table-wrapper">${el.outerHTML}</div>`;
      }
      return content.body.innerHTML;
    },
    animate() {
      if (has(this.$f7route.query.animate)) {
        return Boolean(this.$f7route.query.animate);
      } else {
        return false;
      }
    }
  }
}
</script>
<style>
.article-page__content {
  border-radius: 20px;
  position: relative;
  background-color: white;
  top: -20px;
  padding: 10px 20px;
  box-sizing: border-box;
  transition: opacity 200ms 200ms, transform 200ms 200ms;
  z-index: 1;
}

.article-page__perex {
  font-weight: bold;
}

.article-page__content img,
.article-page__content video {
    max-width: 100%;
    height: auto;
}

.page-next .article-page__content {
  transform: translateY(100vh);
  opacity: 0;
}

.article-page__table-wrapper {
  overflow-x: auto;
}

.article-page__table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.article-page .navbar-transparent {
  background: linear-gradient(to bottom, rgba(0,0,0,0.5), transparent);
}
</style>
