import {defineStore} from './storedef';


export default ({}) => defineStore({
  state: {
    competition: null,
  },
  getters: {
    competition: (state) => state.competition,
  },
  mutations: {
    SET_COMPETITION(state, competition) {
      state.competition = competition
    }
  },
  actions: {
    async FETCH_COMPETITION({commit}, competitionSlug) {
      const {isAxios} = await import('../../services/axios');
      const competition = (await isAxios.get(`/api/public/competition/${competitionSlug}`)).data.data
      commit('SET_COMPETITION', competition);
    }
  }
});
