import {defineStore} from './storedef';
import Vue from 'vue';

const PLAYERS_KEY = 'FavoritePlayers';
const TEAMS_KEY = 'FavoriteTeams';


export default ({}) => defineStore({
  state: {
    playersById: JSON.parse(localStorage.getItem(PLAYERS_KEY)) || {},
    teamsById: JSON.parse(localStorage.getItem(TEAMS_KEY)) || {},
  },
  getters: {
    favoritePlayersById: (state) => state.playersById,
    favoriteTeamsById: (state) => state.teamsById,
  },
  mutations: {
    ADD_FAVORITE_PLAYER(state, value) {
      Vue.set(state.playersById, value.id, value);
      localStorage.setItem(PLAYERS_KEY, JSON.stringify(state.playersById));
    },
    REMOVE_FAVORITE_PLAYER(state, value) {
      Vue.delete(state.playersById, value.id)
      localStorage.setItem(PLAYERS_KEY, JSON.stringify(state.playersById));
    },
    UPDATE_FAVORITE_PLAYER(state, value) {
      if (state.playersById[value.id]) {
        Vue.set(state.playersById, value.id, value);
        localStorage.setItem(PLAYERS_KEY, JSON.stringify(state.playersById));
      }
    },
    ADD_FAVORITE_TEAM(state, value) {
      Vue.set(state.teamsById, value.id, value);
      localStorage.setItem(TEAMS_KEY, JSON.stringify(state.teamsById));
    },
    REMOVE_FAVORITE_TEAM(state, value) {
      Vue.delete(state.teamsById, value.id)
      localStorage.setItem(TEAMS_KEY, JSON.stringify(state.teamsById));
    },
    UPDATE_FAVORITE_TEAM(state, value) {
      if (state.teamsById[value.id]) {
        Vue.set(state.teamsById, value.id, value);
        localStorage.setItem(TEAMS_KEY, JSON.stringify(state.teamsById));
      }
    }
  },
  actions: {
  }
});
