type ArgumentsType<T extends (...args: any[]) => any> = T extends (...args: infer A) => any ? A : never;
interface CacheOptions {
  /**
   * time to live in seconds
   */
  ttl: number,

  /**
   * Use cache only if original call fails
   */
  alwaysRefresh: boolean,
  /**
   * Use only cache
   * use for display from cache and immediately reload from network
   */
  avoidNetwork: boolean,
}



const cacheName = 'api';
export const ARTICLE_CACHE = 'articles';
export const CMS_STATICS = 'cmsStatics';

window['__inFlightCache'] = window['__inFlightCache'] || {};

/**
 * Function that will cache arbitrary cached data
 * @param key
 * @param param1
 */
export function createCache(key:string, {
  ttl=60,
  alwaysRefresh=true,
  avoidNetwork=false,
}: Partial<CacheOptions> = {}) {
  const createCacheKey = (args) => {
    return `/cache/${key}?${JSON.stringify(args)}`;
  }

  return function withCache<T extends (...args: any[]) => any>(fn:T) {


    return async function cacheOrCall(...args: ArgumentsType<T>) {
      if (typeof caches === 'undefined') {
        // @ts-ignore
        window['caches'] = (await import('cache-polyfill')).caches;
      }

      const cacheKey = `${cacheName}-${createCacheKey(args)}`;
      const CACHE_START = `cache-start ${cacheKey}`
      const CACHE_MATCH = `cache-match ${cacheKey}`
      const CACHE_RESOLVE = `cache-resolve ${cacheKey}`
      const CACHE_AFTER_CALL = `cache-after-call ${cacheKey}`
      const CACHE_SAVE = `cache-save ${cacheKey}`
      const CACHE_SAVE_DONE = `cache-save-done ${cacheKey}`
      const CACHE_RESPONSE = `cache-response ${cacheKey}`
      const CACHE_OPEN = `cache-open ${cacheKey}`
      performance.mark(CACHE_START);

      let inFlightPromiseResolver = null;
      window['__inFlightCache'][cacheKey] = new Promise((resolve, reject) => {
        inFlightPromiseResolver = {resolve: (val) => {
          resolve(val);
        }, reject};
      })

      const cacheHit = localStorage.getItem(cacheKey)
      performance.mark(CACHE_MATCH);
      performance.measure(`cache match ${cacheName}`, CACHE_START, CACHE_MATCH)
      if (cacheHit && !alwaysRefresh) {
        const cacheData = JSON.parse(cacheHit);
        const isExpired = new Date() > new Date(cacheData?.headers?.Expires);
        if (!isExpired || avoidNetwork) {
          inFlightPromiseResolver.resolve(cacheData.data)
          delete window['__inFlightCache'][cacheKey];
          return cacheData.data;
        }
      } else if (avoidNetwork) {
        throw new Error('Not in cache');
      }
      performance.mark(CACHE_RESOLVE);
      performance.measure(`cache resove ${cacheName}`, CACHE_MATCH, CACHE_RESOLVE)

      try {
        const response = await fn(...args);
        performance.mark(CACHE_AFTER_CALL)
        performance.measure(`cache call ${cacheName}`, CACHE_RESOLVE, CACHE_AFTER_CALL)
        inFlightPromiseResolver.resolve(response)
        setTimeout(() => {
          performance.mark(CACHE_SAVE);
          localStorage.setItem(cacheKey, JSON.stringify({
            headers: {
              'Expires': new Date(+new Date() + ttl * 1000).toUTCString(),
            },
            data: response,
          }))
          delete window['__inFlightCache'][cacheKey];
          performance.mark(CACHE_SAVE_DONE);
          performance.measure(`cache save ${cacheName}`, CACHE_SAVE, CACHE_SAVE_DONE)
        })
        performance.mark(CACHE_RESPONSE);
        performance.measure(`cache respponse ${cacheName}`, CACHE_AFTER_CALL, CACHE_RESPONSE)
        return response;
      } catch (err) {
        if (cacheHit) {
          const cacheData = JSON.parse(cacheHit);
          inFlightPromiseResolver.resolve(cacheData.data)
          delete window['__inFlightCache'][cacheKey];
          return cacheData.data;
        } else {
          throw err;
        }
      }
    }
  }
}



/*
      const CACHE_START = `cache-start ${cacheKey}`
      const CACHE_MATCH = `cache-match ${cacheKey}`
      const CACHE_RESOLVE = `cache-resolve ${cacheKey}`
      const CACHE_RESPONSE = `cache-response ${cacheKey}`
      const CACHE_OPEN = `cache-open ${cacheKey}`
      performance.mark(CACHE_START);
      const cache = await (preloadedCaches[cacheName] || caches.open(cacheName));
      if (!preloadedCaches[cacheName]) {
        preloadedCaches[cacheName] = cache;
      }
      performance.mark(CACHE_OPEN);
      performance.measure(`cache open ${cacheKey}`, CACHE_START, CACHE_OPEN)
      if (window['__inFlightCache'][cacheKey]) {
        return await window['__inFlightCache'][cacheKey];
      }
      let inFlightPromiseResolver = null;
      window['__inFlightCache'][cacheKey] = new Promise((resolve, reject) => {
        inFlightPromiseResolver = {resolve: (val) => {
          delete window['__inFlightCache'][cacheKey];
          resolve(val);
        }, reject};
      })
      const cacheHit = await cache.match(cacheKey);
      console.log(cacheHit)
      performance.mark(CACHE_MATCH);
      performance.measure(`cache fetch ${cacheKey}`, CACHE_START, CACHE_MATCH)
      if (cacheHit && alwaysRefresh) {
        const isExpired = new Date() > new Date(cacheHit.headers.get('Expires'));
        if (!isExpired) {
          const json = cacheHit.json();
          inFlightPromiseResolver.resolve(json)
          return json;
        }
      }
      performance.mark(CACHE_RESOLVE);
      performance.measure(`cache resolve ${cacheKey}`, CACHE_MATCH, CACHE_RESOLVE)

      try {
        const response = await fn(...args);
        const cacheResponse = new Response(JSON.stringify(response), {
          headers: {
            'Content-Type': 'application/json',
            'Expires': new Date(+new Date() + ttl * 1000).toUTCString(),
          },
        });
        cache.put(cacheKey, cacheResponse);
        inFlightPromiseResolver.resolve(response)
        performance.mark(CACHE_RESPONSE)
        performance.measure(`cache save ${cacheKey}`, CACHE_RESOLVE, CACHE_RESPONSE)
        return response;
      } catch (err) {
        console.warn('failed to update', err);
        if (cacheHit) {
          const json = cacheHit.json();
          inFlightPromiseResolver.resolve(json)
          return json;
        } else {
          inFlightPromiseResolver.reject(err)
          throw err;
        }
      }

    }

  }
}
*/
