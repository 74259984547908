import {get, isString} from 'lodash';

const WEBP_SUPPORTED = (function canUseWebP() {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
  }
  return false;
})();

if (WEBP_SUPPORTED) {
  document.body.classList.add('webp')
}

export default function registerFilters({Vue}: {Vue: import('vue').VueConstructor}) {
  Vue.filter('get', get)
  Vue.filter('isURL', (link) => {
    if (!isString(link) || link.startsWith('data:')) {
      return link;
    }
    const BASE_URL = document.querySelector('[name="backend-url"]').content;
    if (link.startsWith('http')) {
      return link;
    } else {
      return `${BASE_URL}${link}`;
    }

  })
  Vue.filter('resizeImage', (url, width=undefined, height=undefined) => {
    if (!isString(url) || url.startsWith('data:')) {
      return url;
    }
    try {
      new URL(url)
    } catch(err) {
      return null;
    }
    const newUrl = new URL('https://df988106s337z.cloudfront.net/production/');
    newUrl.searchParams.set('url', url);
    if (width) {
      newUrl.searchParams.set('width', width)
    }
    if (height) {
      newUrl.searchParams.set('height', height)
    }
    if (WEBP_SUPPORTED) {
      newUrl.searchParams.set('format', 'webp')
    }
    return newUrl.toString();
  })
}
