<template>
  <div>
    <f7-card
      v-for="option in options"
      :key="option.label"
    >
      <f7-card-content
        @click.native="navigate(option)"
      >
        <div class="navigation-card">
          <div class="navigation-card__content">
            {{ option.label }}
          </div>
          <div class="navigation-card__arrow">
            <f7-icon icon="fal fa-angle-right" />
          </div>
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array
    }
  },
  methods: {
    navigate(option) {
      console.log(option.link);
      this.$f7router.navigate(option.link)
    }
  }
}
</script>
<style>
.navigation-card {
  display: grid;
  grid-template-areas: "content arrow";
  grid-template-columns: 1fr auto;
  align-items: center;
}

.navigation-card__content {
  grid-area: content;
}

.navigation-card__arrow {
  grid-area: arrow;
  font-size: 1.25em;
  line-height: 1;
}
</style>