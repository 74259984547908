import Vue from 'vue';
import {install, Store} from 'vuex';

import Cms from './cms';
import Is from './is';
import IsAuth from './IsAuth';
import Favorites from './favorites'

Vue.use({install});

export default (ctx) => new Store({
  modules: {
    cms: Cms(ctx),
    is: Is(ctx),
    isAuth: IsAuth(ctx),
    favorites: Favorites(ctx),
  },
  actions: {
  },
});
