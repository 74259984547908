import HomePage from './pages/HomePage.vue';
import ArticlePage from './pages/ArticlePage.vue';
import NotFoundPage from './pages/404Page.vue';
import RepresentationTeamsPage from './pages/RepresentationTeamsPage.vue';
import {fetchArticleDetail, fetchFederationArticleDetail} from './services/articles';
import {createCache} from './services/cache';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/news/:id',
    async: async function (routeTo, routeFrom, resolve, reject) {
      const withCache = createCache('articleDetail');
      resolve({component: ArticlePage}, {
        props: {
          article: await withCache(fetchArticleDetail)(parseInt(routeTo.params.id, 10))
        }
      })
    }
  },
  {
    path: '/representation-teams/',
    async: async function (routeTo, routeFrom, resolve, reject) {
      window.vm.$store.dispatch('FETCH_CMS_STATICS');
      resolve({
        component: (await import(/* webpackChunkName: 'RepresentationTeamsPage' */ './pages/RepresentationTeamsPage.vue')).default,
      })
    }
  },
  {
    path: '/competitions',
    async: async function(routeTo, routeFrom, resolve, reject) {
      window.vm.$store.dispatch('FETCH_CMS_STATICS');
      resolve({
        component: (await import(/* webpackChunkName: 'CompetitionsPage' */ './pages/CompetitionsPage.vue')).default,
      })
    },

  },
  {
    path: '/competitions/:slug',
    async: async function(routeTo, routeFrom, resolve, reject) {
      window.vm.$store.dispatch('FETCH_CMS_STATICS');
      resolve({
        component: (await import(/* webpackChunkName: 'CompetitionPage' */ './pages/CompetitionPage.vue')).default,
      })
    },
  },
  {
    path: '/regions/:slug',
    async: async function(routeTo, routeFrom, resolve, reject) {
      window.vm.$store.dispatch('FETCH_CMS_STATICS');
      resolve({
        component: (await import(/* webpackChunkName: 'CompetitionPage' */ './pages/RegionCompetitionsPage.vue')).default,
      })
    },
  },
  {
    path: '/favorites',
    async: async function(routeTo, routeFrom, resolve, reject) {
      resolve({
        component: (await import(/* webpackChunkName: 'AboutPage' */ './pages/AboutPage.vue')).default,
      })
    },
  },
  {
    path: '/match/:type/:id/:slug?',
    async: async function(routeTo, routeFrom, resolve, reject) {
      window.vm.$store.dispatch('FETCH_CMS_STATICS');
      resolve({
        component: (await import(/* webpackChunkName: 'MatchDetailPage' */ './pages/MatchDetailPage.vue')).default,
      })
    },
  },
  {
    path: '/profile',
    async: async function(routeTo, routeFrom, resolve, reject) {
      resolve({
        component: (await import(/* webpackChunkName: 'SettingsPage' */ './pages/SettingsPage.vue')).default,
      })
    },
  },
  {
    path: '/personal-zone',
    routes: [
      {
        path: '/login',
        async: async function(routeTo, routeFrom, resolve, reject) {
          window.vm.$store.dispatch('FETCH_CMS_STATICS');
          resolve({
            component: (await import(/* webpackChunkName: 'PZLoginPage' */ './pages/PersonalZone/LoginPage.vue')).default,
          })
        },
        auth: false,
      },
      {
        path: '/home',
        async: async function(routeTo, routeFrom, resolve, reject) {
          resolve({
            component: (await import(/* webpackChunkName: 'PZHomePage' */ './pages/PersonalZone/HomePage.vue')).default,
          })
        },
        auth: true,
      },
      {
        path: '/my-profile',
        async: async function(routeTo, routeFrom, resolve, reject) {
          resolve({
            component: (await import(/* webpackChunkName: 'PZMyProfilePage' */ './pages/PersonalZone/MyProfilePage.vue')).default,
          })
        },
        auth: true,
      },
      {
        path: '/match-verification',
        async: async function(routeTo, routeFrom, resolve, reject) {
          resolve({
            component: (await import(/* webpackChunkName: 'PZMatchVerificationPage' */ './pages/PersonalZone/MatchVerificationPage.vue')).default,
          })
        },
        auth: true,
      },
      {
        path: '/notifications',
        async: async function(routeTo, routeFrom, resolve, reject) {
          resolve({
            component: (await import(/* webpackChunkName: 'PZNotificationsPage' */ './pages/PersonalZone/NotificationsPage.vue')).default,
          })
        },
        auth: true,
      },
      {
        path: '/federation-article/:id',
        auth: true,
        async: async function (routeTo, routeFrom, resolve, reject) {
          const withCache = createCache('federationArticleDetail_' + routeTo.params.id);
          resolve({component: (await import(/* webpackChunkName: 'PZFederationArticlePage' */ './pages/PersonalZone/FederationArticlePage.vue')).default}, {
            props: {
              article: await withCache(fetchFederationArticleDetail)(parseInt(routeTo.params.id, 10))
            }
          })
        }
      },
    ]
  },
  {
    path: '/representation-teams/:slug',
    async: async function(routeTo, routeFrom, resolve, reject) {
      resolve({
        component: (await import(/* webpackChunkName: 'RepresentationTeamPage' */ './pages/RepresentationTeamPage.vue')).default,
      })
    },
  },
  {
    path: '/competition-teams/:competitionSlug/:competitionTeamId',
    async: async function(routeTo, routeFrom, resolve, reject) {
      resolve({
        component: (await import(/* webpackChunkName: 'ClubDetailPage' */ './pages/ClubDetailPage.vue')).default,
      })
    },
  },
  {
    path: '/players/:playerId/:type',
    async: async function(routeTo, routeFrom, resolve, reject) {
      resolve({
        component: (await import(/* webpackChunkName: 'PlayerDetailPage' */ './pages/PlayerDetailPage.vue')).default,
      })
    },
  },
  {
    path: '/featured-matches',
    async: async function(routeTo, routeFrom, resolve, reject) {
      resolve({
        component: (await import(/* webpackChunkName: 'FeaturedMatchesPage' */ './pages/FeaturedMatchesPage.vue')).default,
      })
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
