import {filter, mapKeys, flow, orderBy, flatMap, mapValues, map, groupBy} from 'lodash';
import {defineStore} from './storedef';
import {fetchCMSStatics} from '../../services/cms';


export default ({}) => defineStore({
  state: {
    competitions: null,
    federationRegions: null,
    representationTeams: null,
  },
  getters: {
    competitionsBySlug: (state) => state.competitions,
    federationRegions: (state) => state.federationRegions,
    federationRegionsBySlug: (state, getters) => mapKeys(getters.federationRegions, 'slug'),
    femaleCompetitions: (state, getters) => flow(
      (_) => filter(_, ({sex, isRegionalCompetition}) => sex === 'female' && !isRegionalCompetition),
      (_) => orderBy(_, ['isRegionalCompetition', ({order}) => parseInt(order, 10)], ['desc', 'asc']),
    )(getters.competitionsBySlug),
    maleCompetitions: (state, getters) => flow(
      (_) => filter(_, ({sex, isRegionalCompetition}) => sex === 'male' && !isRegionalCompetition),
      (_) => orderBy(_, ['isRegionalCompetition', ({order}) => parseInt(order, 10)], ['desc', 'asc']),
    )(getters.competitionsBySlug),
    regionalCompetitions: (state, getters) => flow(
      (_) => filter(_, ({ isRegionalCompetition }) => !!isRegionalCompetition),
      (_) => orderBy(_, ['isRegionalCompetition', ({order}) => parseInt(order, 10)], ['desc', 'asc']),
    )(getters.competitionsBySlug),
    representationTeams: (state) => state.representationTeams,
    representationTeamsBySlug: (state) => mapKeys(state.representationTeams, 'slug'),
    competitionsByRegionSlug: (state, getters) => {
      return flow(
         (_) => flatMap(_, (competition) =>
          map(competition.federationRegions, (region) => ({slug: region.slug, competition}))
        ),
        (_) => groupBy(_, 'slug'),
        (_) => mapValues(_, (val) => flow(
          (_) => map(_, 'competition'),
          (_) => orderBy(_, ({order}) => parseInt(order, 10)),
        )(val)),
      )(getters.regionalCompetitions);
    },
  },
  mutations: {
    SET_COMPETITIONS(state, competitions) {
      state.competitions = flow(
        (_) => orderBy(_, [
          ({order}) => parseInt(order, 10),
          'name',
        ]),
        (_) => mapKeys(_, 'slug'),
      )(competitions)
    },
    SET_FEDERATION_REGIONS(state, federationRegions) {
      state.federationRegions = orderBy(federationRegions, [
        ({order}) => parseInt(order, 10),
        'name',
      ]);
    },
    SET_REPRESENTATION_TEAMS(state, representationTeams) {
      state.representationTeams = representationTeams;
    }
  },
  actions: {
    async FETCH_CMS_STATICS({commit}) {
      const {
        competitions,
        federationRegions,
        represationTeams,
      } = await fetchCMSStatics()

      commit('SET_COMPETITIONS', competitions);
      commit('SET_FEDERATION_REGIONS', federationRegions);
      commit('SET_REPRESENTATION_TEAMS', represationTeams);
    }
  },
});
