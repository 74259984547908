<template>
  <div class="favorite-team" v-if="hasFavorites">
    <h3 class="favorite-team__title">Oblíbená družstva</h3>
    <div class="favorites-list">
      <div
        class="favorite-list__item"
        v-for="team in teams"
        :key="team.id"
      >
        <TeamLogo
          :url="team.photoUrl | isURL | resizeImage(96)"
          :alt="team.name"
          class="favorite-list__image favorite-team__logo"
          type="circle"
          @click="openTeamDetail(team)"
        />
        <div class="favorite-list__name">{{ team.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import {orderBy, isEmpty} from 'lodash';
import TeamLogo from '../Teams/TeamLogo'

export default {
  components: {
    TeamLogo,
  },
  computed: {
    hasFavorites() {
      return !isEmpty(this.teams)
    },
    teams() {
      return orderBy(this.$store.getters.favoriteTeamsById, 'name')
    },
  },
  methods: {
    openTeamDetail(team) {
      this.$f7router.navigate(`/competition-teams/${team.competitionSlug}/${team.id}`);
    }
  }
}
</script>
<style>

.favorite-team__logo {
  width: 48px;
  height: 48px;
}

.favorite-team__title {
  text-transform: uppercase;
}

</style>
