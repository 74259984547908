import {createCache, CMS_STATICS} from './cache';
import {BASE_URL} from './config';

export const cmsFetch = async (query) => {
  const url = new URL(`${BASE_URL}/graphql`);
  url.searchParams.set('query', query);
  const response = await fetch(url.toString(), {
    method: 'GET',
    credentials: 'same-origin',
  })
  if (!response.ok) {
    const err = new Error('Cms Fetch failed');
    err['response'] = response;
    throw err;
  } else {
    return await response.json();
  }
}

export const fetchCMSStatics = async () => {
  const withCache = createCache(CMS_STATICS, {ttl: 5 * 60, alwaysRefresh: false})

  return await (withCache(async () => (await cmsFetch(`{
        Web {
          competitions: Competition(order: "+order") {
            id
            order
            name
            shortName
            slug
            sex
            isFeatured
            isRegionalCompetition
            hasArticles
            federationRegions {
              slug
            }
          }
          federationRegions: FederationRegion(order: "+order") {
            id
            order
            name
            nameInMenu
            slug
          }
          represationTeams: RepresentationTeam(order: "+order") {
            id
            order
            name
            slug
            representationAgeId
          }
        }
      }`
   )).data.Web))()
}
