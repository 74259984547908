<template>
  <f7-app :params="f7params" >
    <!-- Views/Tabs container -->
      <f7-page class="safe-areas">
      <!-- Tabbar for switching views-tabs -->

      <f7-toolbar
        class="toolbar--app-navigation"
        tabbar
        bottom
        labels
        no-hairline
      >
        <f7-link
          tab-link="#view-home"
          tab-link-active
          :icon-only="false"
        >
          <ToolbarIcon :src="toolbarHomeImage" />
          Domů
        </f7-link>
        <f7-link
          tab-link="#view-representation-teams"
          :icon-only="false"
          ref="representationTeamsLink"
          @click="showTab('representationTeams', $event)"
        >
          <ToolbarIcon :src="toolbarCzechFlagIcon" />
          Repre
        </f7-link>

        <f7-link
          tab-link="#view-tournament"
          :icon-only="false"
          ref="tournamentLink"
          @click="showTab('tournament', $event)"
        >
          <ToolbarIcon :src="toolbarCompetitionsImage" />
          Soutěže
        </f7-link>

        <f7-link
          tab-link="#view-featured-matches"
          :icon-only="false"
          ref="featuredMatchesLink"
          @click="showTab('featuredMatches', $event)"
        >
          <ToolbarIcon :src="toolbarMatchesImage" />
          Utkání
        </f7-link>

        <f7-link v-show="!isLoggedIn"
                 tab-link="#view-personal-zone--login"
                 :icon-only="true"
                 ref="linkPersonalZoneLogin"
                 @click="showTab('linkPersonalZoneLogin', $event)"
        >
          <ToolbarIcon :src="toolbarHNetImage" />
          H-NET
        </f7-link>
        <f7-link v-show="isLoggedIn"
                 tab-link="#view-personal-zone--home"
                 :icon-only="true"
                 ref="linkPersonalZoneHome"
                 @click="showTab('linkPersonalZoneHome', $event)"
        >
          <ToolbarIcon :src="toolbarHNetImage" />
          H-NET
        </f7-link>
      </f7-toolbar>

      <!-- <Flipper ref="flipper" :flipKey="flipKey" style="height: 100%;"> -->
        <!-- <div class="tabs-animated-wrap"> -->
          <f7-views tabs>
            <!--<f7-tabs routable animated>-->
              <f7-view
                tab
                id="view-home"
                name="home"
                main
                url="/"
                tab-active
              />
              <f7-view
                v-if="activeTabs.representationTeams"
                id="view-representation-teams"
                name="representation"
                tab
                :url="'/representation-teams/'"
              />
              <f7-view
                v-if="activeTabs.tournament"
                id="view-tournament"
                name="competition"
                tab
                url="/competitions/"
              />
              <f7-view
                v-if="activeTabs.featuredMatches"
                id="view-featured-matches"
                name="featured-matches"
                tab
                url="/featured-matches"
              />
              <f7-view
                v-if="activeTabs.login"
                v-show="!isLoggedIn"
                tab
                id="view-personal-zone--login"
                url="/personal-zone/login"
              />
              <f7-view
                v-if="activeTabs.login"
                v-show="isLoggedIn"
                tab
                id="view-personal-zone--home"
                url="/personal-zone/home"
              />
            <!--</f7-tabs>-->
          </f7-views>
        <!-- </div> -->
      <!-- </Flipper> -->
      <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
    </f7-page>
    <UpdateMessage />
  </f7-app>
</template>
<script>
  import { Device }  from '../framework7';
  import routes from '../routes.js';
  import UpdateMessage from '@designeo/pwa/updater/UpdateMessage.vue';
  // import {Flipper} from '../components/Flipper';
  import ToolbarIcon from '../components/ToolbarIcon.vue';

  export default {
    components: {
      // Flipper,
      UpdateMessage,
      ToolbarIcon
    },
    computed: {
      isLoggedIn() {
        return this.$store.getters.isLoggedIn;
      },
    },
    data() {
      return {
        activeTabs: {
        },
        flipKey: true,
        // Framework7 Parameters
        f7params: {
          id: 'cz.designeo.csh', // App bundle ID
          name: 'CSH', // App name
          theme : 'md',

          // App routes
          routes: routes,


          // Input settings
          input: {
            scrollIntoViewOnFocus: Device.cordova && !Device.electron,
            scrollIntoViewCentered: Device.cordova && !Device.electron,
          },
          // Cordova Statusbar settings
          statusbar: {
            iosOverlaysWebView: true,
            androidOverlaysWebView: false,
          },
          on: {
            tabShow(tab) {
              if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                  event: 'appTabChanged',
                  tab: tab.id,
                });
              }
            },
            routeChanged(to) {
              const {path, route, params} = to;
              if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                  event: 'pageview',
                  path,
                  route: route.path,
                  params: params
                });
              }
            },
          },
        },
        toolbarHomeImage: require('../../images/toolbar/home.svg.vue').default,
        toolbarCzechFlagIcon: require('../../images/toolbar/flag-cz.svg.vue').default,
        toolbarCompetitionsImage: require('../../images/toolbar/competitions.svg.vue').default,
        toolbarMatchesImage: require('../../images/toolbar/matches.svg.vue').default,
        toolbarHNetImage: require('../../images/toolbar/h-net.svg.vue').default,
      }
    },
    methods: {
      async showTab(tab, $event) {
        if ((tab === 'linkPersonalZoneLogin' || tab === 'linkPersonalZoneHome')) {
          if (!this.activeTabs['login'] ) {
            this.$set(this.activeTabs, 'login', true);
            await this.$nextTick();
            this.$refs[tab].$el.click();
          }
        } else if (!this.activeTabs[tab]) {
          this.$set(this.activeTabs, tab, true);
          await this.$nextTick();
          this.$refs[`${tab}Link`].$el.click();
        }
      }
    },
    watch: {
      isLoggedIn(value) {
        if (value) {
          this.$refs.linkPersonalZoneHome.$el.click();
        } else {
          this.$refs.linkPersonalZoneLogin.$el.click();
        }
      }
    },
    mounted() {
      this.$f7ready(async (f7) => {
        // Init cordova APIs (see cordova-app.js)
        if (Device.cordova) {
          const cordovaApp = (await import('../cordova-app.js')).default;
          cordovaApp.init(f7);
        }
        // Call F7 APIs here
      });
    }
  }
</script>
