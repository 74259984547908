<template>
  <f7-page
    name="representationTeamsPage"
    :page-content="false"
  >
    <f7-navbar title="Reprezentace"></f7-navbar>
    <div class="page-content">
      <CardList :options="representationTeams" />
    </div>
  </f7-page>
</template>

<script>
import {flow, sortBy, map} from 'lodash'
import CardList from '../components/Navigation/CardList.vue'

export default {
  components: {
    CardList,
  },
  computed: {
    /**
     * @returns {{link: string, label: string}[]}
     */
    representationTeams() {
      return flow(
        (_) => sortBy(_, 'id', 'asc'),
        (_) => map(_, ({name, slug}) => ({
          label: name,
          link: `/representation-teams/${slug}`,
        }))
      )(this.$store.getters.representationTeams)
    },
  }
}
</script>