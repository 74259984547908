/**
 * Document ready
 * @param {(...any) => any} [fn] - optional callback
 * @return {Promise<void>}
 */
export default (fn?: () => {}): Promise<void> => new Promise((resolve) => {
  const trigger = () => {
    if (typeof(fn) === 'function') {
      fn();
    }
    resolve();
  };
  document.readyState !== 'loading' ? trigger() : document.addEventListener('DOMContentLoaded', trigger);
});
