<template>
  <f7-page
    name="home"
  >
    <f7-navbar>
      <div class="navbar-bg"></div>
      <div class="navbar__logo"></div>
    </f7-navbar>
    <FavoriteTeams class="block" />
    <FavoritePlayers class="block" />
    <ArticleList :pageSize="10" />
  </f7-page>
</template>
<script>
import ArticleList from '../components/Article/ArticleList.vue';
import FavoritePlayers from '../components/Favorites/Players.vue';
import FavoriteTeams from '../components/Favorites/Teams.vue';

export default {
  components: {
    ArticleList,
    FavoritePlayers,
    FavoriteTeams,
  },
  data() {
    return {};
  }
}
</script>
