<template>
  <!-- <Flipped ref="flipper" :flip-id="flipId" v-if="isVisible">
    <keep-alive> -->
    <div>
      <f7-card
        class="article-card"
        :class="{
          [`article-card--${type}`]: type,
        }"
        @click.native="open"
      >
        <div
          class="article-card__content-wrapper"
        >
          <img
            :srcset="`
              ${$options.filters.resizeImage(article.image, 320)} 320w,
              ${$options.filters.resizeImage(article.image, 480)} 480w,
              ${$options.filters.resizeImage(article.image, 640)} 640w,
              ${$options.filters.resizeImage(article.image, 800)} 800w,
            `"
            sizes="80vw"
            loading="lazy"
            class="article-card__image"
          >
          <div class="article-card__content">
            <div class="article-card__title">
              {{ article.title }}
            </div>
          </div>
        </div>
      </f7-card>
    </div>
    <!-- </keep-alive>
  </Flipped> -->
  <!-- placeholder to keep scrollbar intact -->
  <!-- <div v-else>
    <f7-card
      class="article-card"
      style="opacity: 0"
      :class="{
        [`article-card--${type}`]: type,
      }"
      @click.native="open"
    >
      <div class="article-card__content-wrapper">
        <div class="article-card__content">
        </div>
      </div>
    </f7-card>
  </div> -->
</template>
<script>
// import PageVisibilityMixin from '../PageVisibilityMixin';
// import {Flipped} from 'vue-flip-toolkit';

/**
 * @type {Map<string, any>}
 */
const flipStack = new Map();

export default {
  mixins: [
    // PageVisibilityMixin,
  ],
  components: {
    // Flipped,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: null,
    },
    animate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    flipId() {
      return `article-card-${this.article.id}`;
    },
  },
  methods: {
    open() {
      this.$emit('open', {
        routeParameters: {
          id: this.article.id,
        },
        queryParameters: {
          animate: this.animate,
        },
      });
    }
  }
}
</script>
<style>

</style>
