<template>
  <div class="team-logo" @click="$emit('click', $event)">
    <div
      class="team-logo__content"
      :class="{
        'team-logo--loading': loading,
        'team-logo--loaded': isLoaded,
        'team-logo--broken': isBroken,
        'team-logo--circle': type === 'circle',
      }"
    >
      <img
        v-if="currentUrl && !isBroken"
        class="team-logo__image"
        @error="isBroken = true"
        @load="isLoaded = true"
        loading="lazy"
        :src="currentUrl | isURL | resizeImage(240)" />
    </div>
  </div>
</template>
<script>

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      enum: [
        'default',
        'circle'
      ],
      default: 'default'
    },
    alt: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      isBroken: false,
      isLoaded: false,
    };
  },
  computed: {
    currentUrl() {
      return this.url;
    }
  },
}
</script>
<style>
.team-logo {

}

.team-logo__content {
  background-image: url('../../../images/default-team-picture.png');
  width: 100%;
  padding-bottom: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.team-logo__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition: opacity 120ms;
}


.team-logo--circle {
  border-radius: 50%;
  background-size: cover;
}

.team-logo--loading,
.team-logo--fallback {
  background-image: url('../../../images/default-team-picture.png') !important;
  animation: LogoLoading 2000ms linear infinite;
}

.team-logo--loaded {
  background-image: none;
}

.team-logo--loading .team-logo__image {
  opacity: 0;
}

@keyframes LogoLoading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
