import {updater} from '@designeo/pwa/updater/updater'

export const registerServiceWorker = (enabled=false) => {
  if ('serviceWorker' in navigator && enabled) {
    window.addEventListener('load', () => {
      // async function addToCache(name, urls) {
      //   const myCache = await window.caches.open(name);
      //   await myCache.addAll(urls);
      // }
      // ...determine the list of related URLs for the current page...
//      addToCache('apidocs', []);
      let lastServiceWorker = navigator.serviceWorker.controller;
      console.log({lastServiceWorker});
      navigator.serviceWorker.register('/sw.js').then(registration => {
        console.log('SW registered: ', registration);
        registration.onupdatefound = function() {
          var newSW = registration.installing;
          newSW.onstatechange = function() {
            if (newSW.state === 'activated') {
              if (lastServiceWorker !== null) {
                console.log('update ready')
                updater.newVersionAvailable = true;
              }
              lastServiceWorker = newSW
            }
          }
        }

        setInterval(() => {
          registration.update();
        }, 5 * 60 * 1000)
      })
        .catch(registrationError => {
          console.log('SW registration failed: ', registrationError);
        });
    });
  }
};


