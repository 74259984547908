import {first} from 'lodash';
import {cmsFetch} from './cms';

const gql = (str) => {
  return `{Web {${str}}}`;
};

export const fetchArticles = async function({page=0, pageSize=3, competitionId=null, representationTeamId=null}={}) {
  return (await cmsFetch(gql(`
  Article(
    order: "-date",
    date_null: false,
    limit: ${pageSize},
    offset: ${pageSize * page}
    ${competitionId ? `competitions: {id_in: [${competitionId}]}`: ''}
    ${representationTeamId ? `representationTeams: {id_in: [${representationTeamId}]}`: ''}
  ) {
    id,
    title
    image
    date
  }`)
  )).data.Web.Article;
}

export const fetchArticleDetail = async function(id) {
  return first((await cmsFetch(gql(`
  Article(order: "-date", limit: 1, id: ${id}) {
    id,
    title
    perex
    content
    image
    date
    competition {
      name
      sex
      slug
    }
    representationTeam {
      name
    }
    federationRegion {
      name
      tagName
    }
    tags {
      name
    }
  }`))).data.Web.Article);
}


export const fetchFederationArticles = async function({page=0, pageSize=3}={}) {
  return (await cmsFetch(gql(`
  FederationArticle(
    order: "-date",
    date_null: false,
    limit: ${pageSize},
    offset: ${pageSize * page}
  ) {
    id,
    title
    perex
    content
    image
    date
  }`))).data.Web.FederationArticle;
}

export const fetchFederationArticleDetail = async function(id) {
  return first((await cmsFetch(gql(`
  FederationArticle(order: "-date", limit: 1, id: ${id}) {
    id,
    title
    perex
    content
    image
    date
    competition {
      name
      sex
      slug
    }
    representationTeam {
      name
    }
    federationRegion {
      name
      tagName
    }
    tags {
      name
    }
  }`))).data.Web.FederationArticle);
}
