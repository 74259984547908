import { Player } from '../../types/is/Player';

export const COMPETITION = 'competition';
export const REPRESENTATION = 'representation';

export default {
  async fetchDetail(playerId: string): Promise<Player> {
    const { isAxios } = await import('./axios')
    return (await isAxios.get(`/api/public/people/player/${playerId}`)).data.data
  },
}
