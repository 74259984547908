<template>
  <component
    :is="src"
    :height="size"
    :width="size"
    :class="className"
  />
</template>
<script>
export default {
  props: {
    className: {
      type: String,
      default: 'mb-1'
    },
    size: {
      type: [Number, String],
      default: 24
    },
    src: {
      type: null,
      required: true
    }
  },
}
</script>
