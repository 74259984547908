import ready from './helpers/ready'
import Vue from 'vue';
import './framework7/init'

import fixScreenSize from './helpers/fixScreenSize';
import BackButtonHandler from '@designeo/pwa/navigator/BackButtonHandler';
import {registerServiceWorker} from './registerServiceWorker';
import AppCtx from './appCtx';

// Import App Component
import App from './layout/app.vue';

import createStore from './store';

const ctx:AppCtx = <AppCtx>{
  Vue,
};
ctx.store = createStore(ctx);

ready().then(() => {
  Vue.config.performance = true
  const registerFilters = require('./helpers/filters').default;
  registerFilters(ctx);

  const $vm = new Vue({
    el: '#app',
    render: (h) => h(App),
    // @ts-ignore
    store: ctx.store,

    // Register App Component
    components: {
      app: App
    },
  });
  window['vm'] = $vm;

  window.vm = $vm;

  // performance.mark('init');

  fixScreenSize();
  const backButtonHandler = new BackButtonHandler()
  backButtonHandler.on('back', () => {
    $vm.$f7.view.current?.router?.back?.();
    //_.find($vm.$f7.view, ({$el}) => $el[0].classList.contains('tab-active')).router.back();
  });
  backButtonHandler.start();


registerServiceWorker(process.env.ENABLE_SERVICE_WORKER);
})

