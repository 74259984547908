import {isNil, get} from 'lodash';
import {defineStore} from './storedef';

const IS_TOKEN_KEY = 'is_token';
const IS_USER_KEY = 'is_user';
const IS_CODEBOOKS_KEY = 'is_codebooks';

export default ({}) => defineStore({
  state: {
    token: get(JSON.parse(localStorage.getItem(IS_TOKEN_KEY)), 'value', null),
    user: get(JSON.parse(localStorage.getItem(IS_USER_KEY)), 'value', null),
    codebooks: get(JSON.parse(localStorage.getItem(IS_CODEBOOKS_KEY)), 'value', {}),
  },
  getters: {
    token: (state) => state.token,
    isLoggedIn: (state) => !isNil(state.token),
    user: (state) => state.user,
    codebooks: (state) => state.codebooks,
  },
  mutations: {
    SET_IS_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem(IS_TOKEN_KEY, JSON.stringify({value: token}));
    },
    SET_IS_USER(state, user) {
      state.user = user;
      localStorage.setItem(IS_USER_KEY, JSON.stringify({value: user}));
    },
    SET_CODEBOOKS(state, codebooks) {
      state.codebooks = codebooks;
      localStorage.setItem(IS_CODEBOOKS_KEY, JSON.stringify({value: codebooks}));
    }
  },
  actions: {
    async SET_IS_TOKEN({commit}, token) {
      commit('SET_IS_TOKEN', token);
      if (isNil(token)) {
        commit('SET_IS_USER', null);
      }
    },
    async SET_IS_USER({commit}, user) {
      commit('SET_IS_USER', user);
    },
    async SET_CODEBOOKS({commit}, codebooks) {
      commit('SET_CODEBOOKS', codebooks);
    }
  },
});
