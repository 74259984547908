<template>
  <div class="favorite-players" v-if="hasFavorites">
    <h3 class="favorite-player__title">Oblíbení hráči</h3>
    <div class="favorites-list">
      <div
        class="favorite-list__item"
        v-for="player in players"
        :key="player.id"
      >
        <Avatar
          :player="player"
          class="favorite-list__image  favorite-player"
          @click="openPlayerDetail(player)"
        />
        <div class="favorite-list__name">{{ player.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {orderBy, isEmpty} from 'lodash';
import Avatar from '../Player/Avatar';
import { COMPETITION } from '../../services/player.ts'

export default {
  components: {
    Avatar,
  },
  computed: {
    hasFavorites() {
      return !isEmpty(this.players)
    },
    players() {
      return orderBy(this.$store.getters.favoritePlayersById, 'name')
    },
  },
  methods: {
    openPlayerDetail(player) {
      this.$f7router.navigate(`/players/${player.id}/${COMPETITION}`);
    }
  }
}
</script>
<style>
.favorite-players {
  --player-avatar-size: 48px;
}

.favorite-player__title {
  text-transform: uppercase;
}

</style>
