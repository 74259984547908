<template>
  <svg :width="width" :height="height" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1" fill="white">
      <path d="M0 1.6C0 0.716345 0.716344 0 1.6 0H22.4C23.2837 0 24 0.716344 24 1.6V14.4C24 15.2837 23.2837 16 22.4 16H1.6C0.716344 16 0 15.2837 0 14.4V1.6Z"/>
    </mask>
    <path d="M1.69252 0.446727C1.2765 0.174714 0.718741 0.291455 0.446727 0.707476C0.174714 1.1235 0.291455 1.68126 0.707476 1.95327L1.69252 0.446727ZM0.707476 14.0467C0.291455 14.3187 0.174714 14.8765 0.446727 15.2925C0.718741 15.7085 1.2765 15.8253 1.69252 15.5533L0.707476 14.0467ZM22.8 8.9C23.2971 8.9 23.7 8.49706 23.7 8C23.7 7.50294 23.2971 7.1 22.8 7.1V8.9ZM0.707476 1.95327L11.1075 8.75327L12.0925 7.24673L1.69252 0.446727L0.707476 1.95327ZM11.1075 7.24673L0.707476 14.0467L1.69252 15.5533L12.0925 8.75327L11.1075 7.24673ZM11.6 8.9H22.8V7.1H11.6V8.9ZM1.6 1.8H22.4V-1.8H1.6V1.8ZM22.4 14.2H1.6V17.8H22.4V14.2ZM1.8 14.4V1.6H-1.8V14.4H1.8ZM1.6 14.2C1.71046 14.2 1.8 14.2895 1.8 14.4H-1.8C-1.8 16.2778 -0.277767 17.8 1.6 17.8V14.2ZM22.4 17.8C24.2778 17.8 25.8 16.2778 25.8 14.4H22.2C22.2 14.2895 22.2895 14.2 22.4 14.2V17.8ZM22.4 1.8C22.2895 1.8 22.2 1.71046 22.2 1.6H25.8C25.8 -0.277769 24.2778 -1.8 22.4 -1.8V1.8ZM1.6 -1.8C-0.277769 -1.8 -1.8 -0.277767 -1.8 1.6H1.8C1.8 1.71046 1.71046 1.8 1.6 1.8V-1.8ZM22.2 1.6V14.4H25.8V1.6H22.2Z" fill="currentColor" mask="url(#path-1-inside-1)"/>
  </svg>
</template>
<script>
export default {
  extends: require('./SvgMixin').default,
}
</script>