<template>
  <div>
    <f7-block class="article-list">
      <template
        v-for="(article, index) in articles"
      >
        <ArticleCard
          v-if="article"
          :key="index"
          :article="article"
          :animate="animate"
          class="article-list__card"
          @open="onArticleOpen"
        />
        <f7-card
          v-else
          :key="index"
          class="article-card article-list__card article-card--loading"
        >
          <div class="article-card__content-wrapper">
          <div class="article-card__content"></div>
        </div>
        </f7-card>
      </template>

      <f7-button
        v-if="!reachedEnd"
        :disabled="isLoading"
        @click="fetchArticles"
      >
        Další články
      </f7-button>
    </f7-block>
  </div>
</template>
<script>
import {reject} from 'lodash';
import ArticleCard from './ArticleCard.vue';
import {fetchArticles} from '../../services/articles';
import {createCache, ARTICLE_CACHE} from '../../services/cache';

export default {
  props: {
    pageSize: {
      type: Number,
      default: 10,
    },
    competitionId: {
      type: Number,
      default: null,
    },
    representationTeamId: {
      type: Number,
      default: null,
    },
    animate: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    ArticleCard,
  },
  data() {
    return {
      isLoading: false,
      articles: [],
      page: 0,
      reachedEnd: false,
    };
  },
  mounted() {
    performance.mark('articleList mounted');
    this.fetchArticles();
  },
  methods: {
    async fetchArticles() {
      performance.mark('articleList fetchArticles');
      const withCache = createCache(ARTICLE_CACHE);

      this.isLoading = true;
      try {
        // add placeholders
        this.articles = this.articles
          .concat([null, null, null])

        performance.mark('articleList fetchStart');
        const fetchedArticles = await withCache(fetchArticles)({
            pageSize: this.pageSize,
            page: this.page,
            competitionId: this.competitionId,
            representationTeamId: this.representationTeamId,
          })
        performance.mark('articleList fetchDone');

        this.page += 1
        if (fetchedArticles.length < this.pageSize) {
          this.reachedEnd = true;
        }

        this.articles = this.articles.concat(fetchedArticles)
      } finally {
        // remove placeholders
        this.articles = reject(this.articles, (x) => x === null);
        this.isLoading = false;
      }
      performance.mark('articleList loaded');
      performance.measure('article list', 'articleList mounted', 'articleList loaded')
      performance.measure('article list fetch', 'articleList fetchStart', 'articleList fetchDone')
    },
    onArticleOpen(payload) {
      const queryParameters = new URLSearchParams(payload.queryParameters);
      this.$f7router.navigate(`/news/${payload.routeParameters.id}?${queryParameters}`);
    }
  }
}
</script>
<style>
.article-list {

}

.article-list__card {
  margin: 0 0 16px;
}
</style>