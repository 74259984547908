<template>
  <div class="player-avatar" @click="$emit('click', $event)">
    <img
      v-if="player.photoUrl && !forceInitials"
      :src="player.photoUrl | isURL | resizeImage(96)"
      class="player-avatar__image"
      @error="forceInitials = true"
      loading="lazy"
    >
    <span class="player-avatar__initials" v-else>{{ player.name | initials }}</span>
  </div>
</template>
<script>
import {orderBy, flow, split, map, join} from 'lodash';

export default {
  filters: {
    initials(name) {
      return flow(
        x => split(x, ' '),
        x => map(x, (x) => x[0]),
        x => join(x, '')
      )(name);
    }
  },
  props: {
    player: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      forceInitials: false,
    }
  }
}
</script>
<style>
:root {
  --player-avatar-size: 48px;
}

.favorite-player {
  width: var(--player-avatar-size);
  height: var(--player-avatar-size);
  border-radius: 50%;
  background-color: var(--f7-theme-color);
  color: white;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--player-avatar-size) * 0.4);
  overflow: hidden;
}

.player-avatar__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
</style>
